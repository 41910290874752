<template>
    <Page :title="$t('unit.title')">
        <div>{{ $t('unit.dummy_text') }}</div>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
export default {
    components: { Page },
};
</script>
